import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/SEO"
import Link from "../components/Link"
import NakedList from "../components/NakedList"

const StyledListItem = styled("li")`
    margin-top: 2vh;
    margin-bottom: ${props => (props.hasQuote ? "7vh" : "2vh")};
`

const Date = styled("time")`
    display: inline-block;
    padding-left: 1rem;
    color: #888;
`

const PressItem = ({ name, date, url, quote }) => {
    return (
        <StyledListItem hasQuote={!!quote}>
            {quote && <p>{quote}</p>}
            {quote && <span>—</span>}
            <Link href={url}>{name}</Link>
            <Date>{date}</Date>
        </StyledListItem>
    )
}

const StyledList = styled(NakedList)`
    max-width: 666px;
    margin: 10vh auto 0;
    padding-bottom: 20vh;
`

const PressPage = ({ data }) => {
    const allPress = data.allPress.edges
        .map(edge => edge.node.data)
        .map(data => ({
            name: data.Name,
            date: data.Date,
            formattedDate: data.formattedDate,
            url: data.URL,
            quote: data.Quote
        }))
    return (
        <React.Fragment>
            <SEO title="Press" />
            {allPress && (
                <StyledList>
                    {allPress.map((press, index) => (
                        <PressItem
                            key={index}
                            {...press}
                            date={press.formattedDate}
                        />
                    ))}
                </StyledList>
            )}
        </React.Fragment>
    )
}

export const query = graphql`
    query GetPress {
        allPress: allAirtable(filter: { table: { eq: "Press" } }) {
            edges {
                node {
                    data {
                        Name
                        Date
                        formattedDate: Date(formatString: "DD MMM YY")
                        URL
                        Quote
                    }
                }
            }
        }
    }
`

export default PressPage
